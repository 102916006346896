<template>
    <v-container>
        <v-card class="mt-16 pa-5">
            <v-card-title class="font-weight-bold">
                MSN Content Guidelines
            </v-card-title>

            <v-row class="align-center justify-center">
                <v-col cols="11" class="pa-8">
                    <p>
                        Here are some guidelines to help you get your own
                        content out on MSN.
                    </p>

                    <ol>
                        <li class="mb-4">
                            Content published on MSN is categorized as
                            <strong>
                                brand-sponsored informational content
                            </strong>
                            and will need the following disclaimer added at the
                            end.
                            <br />
                            <i>
                                This content is provided in partnership with
                                [Brand Name – can be hyperlinked to brand site]
                                and is intended for informational purposes only.
                                The views, opinions, and advice expressed in
                                this article are solely those of [Brand Name]
                                and do not necessarily reflect the views or
                                policies of any other individual, organization,
                                or entity.
                            </i>
                        </li>

                        <li>
                            The content should first and foremost be
                            <strong>informational</strong>
                            and
                            <strong>interesting,</strong>
                            covering a topic of interest in the sponsoring
                            brand’s industry;
                        </li>

                        <ul class="mb-4">
                            <li>
                                The topic needs to be
                                <strong>relevant</strong>
                                to the present moment – tying it in with recent
                                industry relevant news or developments is
                                recommended. A versatile solution is to comment
                                on a recent development and what it might mean
                                for the target audience,
                                <strong>
                                    citing the sponsoring brand as the authority
                                    source behind the commentary;
                                </strong>
                            </li>

                            <ul>
                                <li>
                                    Idea: look up “[industry] news” and see if
                                    there are any recent developments you could
                                    be using as the hook for your content; then
                                    think about “what this means for the target
                                    reader” and answer that in your content.
                                </li>
                            </ul>

                            <li>
                                The topic needs to be
                                <strong>specific</strong>
                                (generic “introduction to plumbing” or “why you
                                need regular dental checkups” are low-interest
                                and may get your brand banned from MSN
                                publication)
                            </li>
                        </ul>

                        <li class="mb-4">
                            The content
                            <strong>should not be an open, direct ad</strong>
                            for the sponsoring business. You should not directly
                            and explicitly present the business as the obvious
                            choice for the reader and talk about the business’s
                            advantages over the competition, and you should
                            NEVER disparage, criticize, or mention the
                            competition in any type of negative context.
                        </li>

                        <li class="mb-4">
                            The content should be
                            <strong>easy to scan.</strong>
                            Use sub-headings and bulleted lists, and avoid large
                            text-wall paragraphs.
                        </li>

                        <li class="mb-4">
                            The content should be
                            <strong>accurate,</strong>
                            fact-checked, and deliver genuine value to your
                            audience.
                        </li>

                        <li>
                            For click-through purposes, it’s recommended to
                            <strong>
                                add your target URL/s as the first and last
                                links in the content;
                            </strong>
                            the links should be placed naturally and in an
                            engaging and relevant way.
                        </li>

                        <ul class="mb-4">
                            <li>
                                For example:
                                <i>
                                    “And if you need inspiration, you can always
                                    turn to celebrities and see what’s popular –
                                    <a
                                        href="https://allstarshades.com/celebrity-sunglasses/"
                                        target="_blank"
                                    >
                                        which sunglasses are Hollywood A-listers
                                        wearing in 2024.
                                        <!-- eslint-disable-next-line prettier/prettier -->
                                    </a>”
                                </i>
                            </li>
                            <li>
                                It’s fine to add one or two third-party links,
                                as sources, but do make sure the
                                <a
                                    href="https://ampifire.com/blog/link-outreach-process/"
                                    target="_blank"
                                >
                                    links
                                </a>
                                you want to promote are predominant.
                            </li>
                        </ul>

                        <li>
                            The content should
                            <strong>not be generic AI output;</strong>
                            low-quality AI content can get the brand banned from
                            MSN. You can use
                            <a
                                href="https://ampifire.com/blog/shortlyai-review/"
                                target="_blank"
                            >
                                AI for research
                            </a>
                            and ideas, but the final content should be 100%
                            fact-checked, edited for clarity and accuracy, and
                            free of typical AI exaggerations. Keep an eye out
                            for the following AI red flags known to cause
                            issues:
                        </li>

                        <ul>
                            <li>
                                AI text generators will generally exaggerate the
                                impact, importance, of relative status of the
                                subject it writes about. It may present your
                                brand as the absolute leader in your industry
                                (even though it’s not), and it may state that
                                the topic you’re writing about is of crucial
                                importance for the field (even though it may not
                                be).
                            </li>
                            <li>
                                AI will contextualize every topic as a
                                “landscape” to “navigate” or “realm” to “dive
                                into”, even though that’s inappropriate in most
                                cases (as your content will be about specific
                                topics, not about entire “landscapes” or
                                “realms”).
                            </li>
                            <li>
                                AI will present inaccurate or downright wrong
                                information as factual, and may even list
                                sources for its claims (even though said sources
                                are either misquoted or completely made up).
                                <strong>
                                    All information should be thoroughly and
                                    manually checked for accuracy.
                                </strong>
                            </li>
                        </ul>
                    </ol>

                    <p class="mt-8 mb-0 font-weight-bold">
                        Examples of Content Published on MSN that Follow the
                        Guidelines
                    </p>
                    <p>Now, here are some example of MSN content:</p>
                    <ul>
                        <li>
                            <a
                                href="https://www.msn.com/en-us/movies/news/from-robert-downey-jr-to-simu-liu-why-is-dita-the-celeb-eyewear-of-choice/ar-BB1jH1k6"
                            >
                                From Robert Downey Jr. to Simu Liu: Why is DITA
                                the celeb eyewear of choice?
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.msn.com/en-us/lifestyle/lifestyle-buzz/the-rock-vs-pharrell-williams-their-most-ridiculous-sunglasses/ar-BB1jGW2P"
                            >
                                The Rock vs. Pharrell Williams: Their Most
                                Ridiculous Sunglasses
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.msn.com/en-us/health/other/3-menopause-supplements-for-healthy-weight-and-why-they-might-be-better-than-hormone-therapy/ar-BB1jHaj2"
                            >
                                3 Menopause Supplements for Healthy Weight (And
                                Why They Might Be Better than Hormone Therapy)
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.msn.com/en-us/health/other/drew-barrymore-vs-michelle-obama-how-they-survive-menopause/ar-BB1jGRns"
                            >
                                Drew Barrymore vs. Michelle Obama: How They
                                Survive Menopause
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.msn.com/en-us/money/realestate/dfw-multifamily-realty-more-attractive-amid-50-increase-in-population-by-2050/ar-BB1jdACn"
                            >
                                DFW multifamily realty more attractive amid 50%
                                increase in population by 2050 (msn.com)
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.msn.com/en-us/money/insurance/latest-medicare-updates-have-complex-impact-on-group-health-insurance-for-smbs/ar-BB1iEgmi"
                            >
                                Latest Medicare updates have complex impact on
                                group health insurance for SMBs (msn.com)
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.msn.com/en-us/lifestyle/lifestyle-buzz/go-big-or-go-back-to-the-90s-here-are-the-top-eyewear-trends-for-2024/ar-BB1iXRre"
                            >
                                Go big—or go back to the ’90s. Here are the top
                                eyewear trends for 2024 (msn.com)
                            </a>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class MSNContentGuidelines extends Vue {}
</script>

<style lang="scss" scoped>
a {
    display: inline-flex;
}
</style>
